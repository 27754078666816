/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
.windows,
.linux {
    /* total width */
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    /* background of the scrollbar except button or resizer */
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-track:hover {
        background-color: transparent;
    }

    /* scrollbar itself */
    ::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 8px;
        border: 4px solid #707683;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: transparent;
    }

    /* set button(top and bottom of the scrollbar) */
    ::-webkit-scrollbar-button {
        display: none;
    }
}

@import "../../../../styles/abstracts.scss";

.infoModal {
    width: 100%;
    height: 100%;
    background-image: var(--bgModalMobile);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    color: colors("white");
    z-index: zindex("base");

    .container {
        @include borderGradient;
        width: 100%;
        height: 100%;
        padding: 24px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        .title {
            @include font(32, 36, 0.1, 900);
            color: colors("purpleLight");
        }

        .description {
            @include font(28, 32, 0.1, 400);
            margin: 24px 0;
            text-align: center;
        }

        .backIcon {
            position: absolute;
            top: 65px;
            left: 0px;
        }
    }
}

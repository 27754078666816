@import "../../../../styles/abstracts.scss";

.AuthLogin {
    @include authPage;
    justify-content: flex-start;

    .formWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .form {
        @include formLayout;
    }

    .singleField {
        @include singleField;
    }
}

@import '../../../../styles/abstracts.scss';

.matchDetails {
    @include featurePage;

    .container {
        @include featureContainer;
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
    }

    .header {
        width: 100%;
        margin-top: 16px;
        display: flex;
        justify-content: space-around;
    }

    .matchWrapper {
        width: 100%;
        margin: 16px;
    }

    .locationImage {
        width: 80%;
    }

    .location {
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-bottom: 16px;
    }

    .locationIcon {
        margin-right: 5px;
    }

    .locationAddress {
        text-decoration: underline;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: colors('white');
    }

    .joinButton {
        margin-top: 16px;
        width: 90%;
    }

    @include from-breakpoint('medium') {
        .joinButton {
            // max-width: 280px;
        }
    }
}

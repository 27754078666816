@import '../../../../styles/abstracts.scss';

.myMatch {
    width: 100%;
    padding: 8px;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    background-color: $inputBg;
    border-radius: 5px;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }

    .locationImg {
        width: 100%;
        max-width: 160px;
        margin-right: 12px;
    }
    
    .matchDetails {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        margin-top: 20px;
        @include font(12, 14.4);
    }

    .menuButton {
        color: $white;
        align-self: flex-start;
    }

    .content {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    }
}
@import '../../../../styles/abstracts.scss';

.TopNavigation {
    display: none;
    width: 100%;
    margin: 20px 0 16px 0;

    Button {
        margin: 16px;
    }

    .iconButton {
        margin: 0 6px;
        width: 15px;
    }

    @include from-breakpoint('x-large') {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        Button {
            width: 165px;
        }
    }
}

@import "../../../../styles/abstracts.scss";

.teams {
    width: 100%;

    .buttonsWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .button {
        border: 0;
        background: transparent;
        display: flex;
        align-items: center;
        cursor: pointer;
        @include font(20, 24, 0.1, 900);

        &:first-child {
            color: $purpleLight;
        }

        &:last-child {
            color: $appBlueLight;
        }

        img {
            margin-right: 5px;
            visibility: hidden;
        }
        
        &.active {
            span {
                border-bottom: 1px solid currentColor;
            }

            img {
                visibility: visible;
            }
        }
    }

    .players {
        margin-top: 16px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .openMatchTitle {
        @include font(20, 24, 0.1, 900);
        text-align: center;
        color: $purpleLight;
    }

    .openMatchButtons {
        display: flex;
        justify-content: center;

        button {
            width: 15px;
            height: 15px;
            border: 1px solid $purpleLight;
            border-radius: 50%;
            background-color: transparent;

            &:first-child {
                margin-right: 16px;
            }

            &.active {
                background-color: $purpleLight;
            }
        }
    }
}
@import "./../styles/abstracts.scss";

.App {
    height: 100%;
    position: relative;
    background-image: var(--bgMobile);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    padding: 28px 28px 78px 28px;
    display: flex;
    z-index: zindex("base");

    .toqueLogo,
    .profileLogo {
        cursor: pointer;
        position: absolute;
        display: none;
        top: 16px;
        z-index: zindex("overlay");
    }

    .toqueLogo {
        left: 16px;
    }

    .profileLogo {
        right: 16px;
    }

    .main {
        @include borderGradient;
        flex-grow: 1;
        filter: drop-shadow(
            5px 15px 15px colors("backgroundTransparentFilter")
        );

        .toqueLogo,
        .profileLogo {
            display: block;
        }
    }

    @include from-breakpoint("small") {
        background-image: var(--bgDesktop);
    }

    @include from-breakpoint("medium") {
        .main {
            margin: 0 auto;
            max-width: 587px;
        }
    }

    @include from-breakpoint("x-large") {
        .toqueLogo,
        .profileLogo {
            display: block;
            top: 48px;
            width: 110px;
        }

        .toqueLogo {
            left: 28px;
        }

        .profileLogo {
            right: 28px;
        }

        .main {
            max-width: 968px;

            .toqueLogo,
            .profileLogo {
                display: none;
            }
        }
    }
}

@import "../../../../styles/abstracts.scss";

.AuthRegister {
    @include authPage;

    .form {
        @include formLayout;
    }

    .fieldGroup {
        @include fieldGroup;

    }
    
    .item {
        @include fieldGroupItem;
    }

    .phonePrefix {
        width: 100%;
        max-width: 90px;
    }

    .phoneNumber {
        width: 100%;
    }

    .singleField {
        @include singleField;
    }
    
    .editHeader {
        width: 100%;
        text-align: center;
    }

    .editTitle {
        color: $white;
        @include font(18, 22);
    }

    .userEmail {
        @include font(18, 22);
        display: block;
        text-align: center;
        margin-bottom: 16px;
    }

    @include from-breakpoint('medium') {
        .phonePrefix {
            max-width: 120px;
        }
    }
}

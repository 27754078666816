$appBlueLight: #0ae3e3;
$backgroundTransparent: rgba(0, 0, 0, 0.6);
$backgroundTransparentFilter: rgba(0, 0, 0, 0.5);
$black: #020305;
$disabled: #c4c4c4;
$error: #f26f6f;
$facebook: #1877f2;
$inputBg: #2d2d2d;
$purple: #a9197d;
$purpleLight: #e30a65;
$white: #ffffff;
$grey: #5c5c5c;
$lightGrey: #eee;

@import "./../../../styles/abstracts.scss";

.Home {
    height: 100%;
    position: relative;
    overflow: auto;
    padding: 16px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;

    .title {
        @include font(18, 22, 0.1, 400);
    }

    .subtitle {
        @include font(30, 30, 0.1, 700);
    }

    .menu {
        display: flex;
        flex-flow: column;
        width: 100%;

        Button {
            margin: 16px;
        }
    }

    .registerButton {
        display: none;
    }

    .iconButton {
        margin: 0 6px;
        width: 15px;
    }

    .player {
        color: colors("purpleLight");
    }

    @include from-breakpoint("x-large") {
        .menu {
            order: 1;
            flex-flow: row;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 16px;

            Button {
                width: 165px;
                margin: 0;
            }

            .registerMobile {
                display: none;
            }
        }

        .title {
            order: 2;
            @include font(40, 40, 0.1, 400);
        }

        .player {
            @include font(60, 74, 0.1, 400);
            order: 3;
        }

        .subtitle {
            order: 4;
            @include font(80, 96, 0.1, 400);
        }

        .registerButton {
            display: block;
            order: 5;
        }
    }
}

@import '../../../../styles/abstracts.scss';

.CreateMatch {
    @include featurePage;

    .content {
        @include featureContainer;
        @include infiniteScrollContainer(390px);
    }

    .formRow {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        flex-wrap: wrap;
        gap: 12px;
    }

    .label {
        width: 40%;
        text-align: right;
    }

    .textField {
        width: 50%;
    }

    .radioGroup {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .actions {
        margin: 16px;
    }

    .fieldError {
        width: 100%;
        text-align: center;
    }

    @include from-breakpoint('medium') {
        .content {
            @include infiniteScrollContainer(420px);
        }
    }
}
@import "../../../../styles/abstracts.scss";

.ListMatches {
    @include featurePage;

    .container {
        @include featureContainer;
        height: 100%;
    }

    .fieldGroup {
        display: flex;
        justify-content: space-around;
        width: 100%;

        .item {
            @include fieldGroupItem;
        }
    }

    .matchesHeader {
        width: 100%;
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
    }

    .matchesContainer {
        @include infiniteScrollContainer(430px);
        position: relative;
    }

    .matchesContainerAdmin {
        @include infiniteScrollContainer(530px);
    }

    .loading {
        @include infiniteLoader();
    }

    .loadingSmall {
        @include infiniteLoader('small');
    }

    .notMatchesFound {
        display: flex;
        justify-content: center;
        align-items: center;
        @include infiniteScrollContainer(530px);
    }

    .actions {
        margin-top: 16px;
        width: 100%;
    }

    
    @include from-breakpoint('medium') {
        .matchesContainer {
            @include infiniteScrollContainer(450px);
        }

        .matchesContainerAdmin {
            @include infiniteScrollContainer(550px);
        }
    }
}

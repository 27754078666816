.primary-button-gradient {
    background: linear-gradient(colors("purpleLight"), colors("purple"));

    &:hover {
        filter: brightness(0.8);
    }

    &:disabled {
        background: colors("disabled") !important;
        color: colors("white") !important;
    }
}

.facebook-button {
    background: colors("facebook") !important;
    color: colors("white") !important;
}

.google-button {
    background: colors("white") !important;
    color: colors("backgroundTransparentFilter") !important;
}

.facebook-button,
.google-button {
    &:hover {
        filter: brightness(0.8);
    }

    &:disabled {
        filter: contrast(0.5);
    }
}

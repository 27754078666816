@import '../../../../styles/abstracts.scss';

.ListFriends {
    @include featurePage;

    .container {
        @include featureContainer;
        height: 100%;
        justify-content: space-between;
    }

    .list {
        @include infiniteScrollContainer(400px);
        width: 100%;
        margin-top: 16px;
        position: relative;
    }

    .emptyData {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 250px;
    }

    .loading {
        @include infiniteLoader();
    }

    .loadingSmall {
        @include infiniteLoader('small');
    }

    .button {
        &:first-of-type {
            margin-bottom: 16px;
        }
    }

    .errorMessage {
        text-align: center;
        color: $purpleLight;
    }

    .searchInput {
        max-width: 280px;
    }
}

.InviteMode {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $grey;
    z-index: 4;
    margin: 0;
    padding: 60px 16px 30px;
}

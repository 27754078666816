@import '../../../../styles/abstracts.scss';

.ProfileDetails {
    @include featurePage;

    .content {
        @include featureContainer;
        @include font(18, 22, 0.1, 400);
        text-align: center;
        height: 100%;
        overflow-y: auto;
        justify-content: space-between;

        .avatarImage {
            width: 80px;
            margin-bottom: 8px;
        }
    }

    .playerName {
        @include font(32, 36, 0.1, 900);
        max-width: 80px;
        text-align: center;
        margin: 8px 0;
    }

    .statIcon {
        margin: 8px 0;
    }

    .stats {
        width: 100%;
        display: flex;
        flex-flow: column;
        max-width: 250px;

        div {
            display: flex;
            justify-content: space-between;

            p {
                border-radius: 4px;
                margin: 8px 0;
                padding: 4px;
                min-width: 80px;
                text-align: left;

                &:last-child {
                    background: colors('inputBg');
                    text-align: center;
                }
            }
        }
    }

    .buttonsWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 12px;
    }

    @include from-breakpoint('small') {
        .content {
            justify-content: space-evenly;
        }
    }

    @include from-breakpoint('medium') {
        .playerName {
            @include font(32, 36, 0.1, 900);
            max-width: 100%;
        }
    }

    @include from-breakpoint('x-large') {
        @include infiniteScrollContainer(250px);
        margin: 8px 0;
    }
}

@import "../../../../styles/abstracts.scss";

.ListMyMatches {
    @include featurePage;

    .container {
        @include featureContainer;
        height: 100%
    }

    .subtitle {
        @include font(22, 26, 0.1, 900);
    }

    .loading {
        @include infiniteLoader();
    }

    .loadingSmall {
        @include infiniteLoader('small');
    }

    .emptyData {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 250px;
    }

    .matchesContainer {
        @include infiniteScrollContainer(360px);
        position: relative;
    }


    @include from-breakpoint('medium') {
        .matchesContainer {
            @include infiniteScrollContainer(380px);
        }
    }
}

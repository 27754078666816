@import "../../../../styles/abstracts.scss";

.AuthHeader {
    display: flex;
    position: relative;
    color: colors("purpleLight");
    flex-flow: column;
    align-items: center;
    margin: 8px 0;
    width: 100%;

    h1 {
        @include font(24, 20, 0.1, 900);
        margin-top: 16px;
        text-align: center;
    }

    .backIcon {
        position: absolute;
        top: 45px;
        left: 0px;
    }
}

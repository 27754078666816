@import '../../../../styles/abstracts.scss';

.HeadquartersDetails {
    @include featurePage;

    h1 {
        margin: 16px 0 0 0;
    }

    .container {
        @include featureContainer;
        height: 100%;
    }

    .address {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .addressLink {
        margin: 0 8px;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: colors('white');
    }

    .imagesWrapper {
        width: 100%;
        height: 164px;
        max-width: calc(100vw - 102px);
        overflow-x: scroll;
        margin-bottom: 12px;
    }

    .images {
        display: flex;
        flex-flow: row;
        gap: 8px;
    }

    .imageContainer {
        min-width: 250px;
        height: 150px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .noImages {
        @include font(24, 28, 0.2, 500);
        color: colors('purpleLight');
        margin: 32px 0;
        padding: 0 16px;
        text-align: center;
    }

    .details {
        padding: 0 16px;
        margin: 16px 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;

        span {
            min-width: 50%;
        }
    }

    .matches {
        @include infiniteScrollContainer(470px);
    }

    .matchesAdmin {
        @include infiniteScrollContainer(530px);
    }

    .editButton {
        margin-top: 16px;
        max-width: 300px;
    }

    .loadingSmall {
        @include infiniteLoader('small');
    }

    .emptyData {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    
    @include from-breakpoint('medium') {
        .matches {
            @include infiniteScrollContainer(520px);
            padding: 0 16px;
        }

        .matchesAdmin {
            @include infiniteScrollContainer(580px);
        }
    }

    @include from-breakpoint('x-large') {
        .container {
            max-width: 100%;
        }

        .details {
            flex-wrap: nowrap;
        }

        .imagesWrapper {
            width: 100%;
            max-width: 968px;
            overflow-x: scroll;
        }

        .images {
            justify-content: center;
        }

        .imageContainer {
            margin: 0;
        }

        .infitineScrollWrapper {
            max-width: 480px;
            margin: 0 auto;
        }

        .editButton {
            margin-top: 30px;
        }
    }
}

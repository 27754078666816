// This removes print header URL From the page
@page {
    // size: auto;
    // margin: 0mm;
}

// This media supports Print overriden styles. And Support adding styles for printable elements.
@media print {
    // * {
    //     overflow: visible !important;
    // }

    // body {
    //     visibility: hidden;
    //     color: colors("black") !important;
    //     background: colors("white") !important;
    // }
}

@import './../../../../styles/abstracts.scss';

.counter {
    margin-bottom: 8px;
    text-align: center;

    &:last-of-type {
        margin-bottom: 0;
    }

    &__form {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    &__button {
        padding: 4px;
    }

    &__input {
        width: 100px;
        text-align: center;
    }
}
* {
    background-repeat: no-repeat;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    font-family: "Bebas Neue", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;

    &:before,
    &:after {
        box-sizing: border-box;
    }
}

html {
    //Prevent iOS text size adjust after orientation change, without disabling user zoom.
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 16px;
    line-height: 1.2rem;
    height: 100%;

    body {
        margin: 0;
        background: colors("black");
        color: colors("white");
        min-height: 100vh;
        height: 100%;
        overflow-x: hidden;
        font-family: "Montserrat", sans-serif !important;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            margin: 0;
        }

        h1 {
            font-size: 2em;
        }

        h2 {
            font-size: 1.5em;
        }

        h3 {
            font-size: 1.25em;
        }

        #root {
            height: 100%;
        }

        input {
            color: colors("white") !important;
            height: 28px !important;
            padding: 4px 16px !important;
        }

        .fieldError {
            color: colors("error");
        }

        .loadingPage {
            @include loadingPage;
        }
    }

    @include from-breakpoint("medium") {
        font-size: 18px;
    }

    @include from-breakpoint("large") {
        font-size: 20px;
    }
}

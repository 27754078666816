@import '../../../../styles/abstracts.scss';

.match {
    width: 100%;
    background-color: $inputBg;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 30px;
    display: flex;

    &:last-of-type {
        margin-bottom: 0;
    }

    span {
        vertical-align: middle;
    }

    .matchDetails {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        row-gap: 12px;
        text-align: center;
        
        @include font(12, 14);
    }

    .menuButton {
        color: $white;
        align-self: flex-start;
    }
}

.clickable {
    cursor: pointer;
}
@import "../../../../styles/abstracts.scss";

.player {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    margin: 5px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    
    .avatar {
        margin-bottom: 8px;
    }

    .name, .position {
        @include font(12, 14.4);
        color: $white;
    }

    .position {
        color: $purpleLight;
    }
}
@import '../../../../styles/abstracts.scss';

.AuthSelection {
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;

    .body {
        @include font(18, 22, 0.1, 400);
        margin: 16px;
        text-align: center;

        p {
            margin: 8px;
        }

        span {
            @include font(22, 24, 0.1, 700);
            color: colors('purpleLight');
        }
    }

    .actions {
        display: flex;
        flex-flow: column;
        margin: 8px;
        flex: 1;
        width: 90%;

        Button {
            margin: 8px 0;
        }
    }

    .socialNetworkWrapper {
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;

        h2 {
            margin: 0;
            text-align: center;
        }

        div {
            display: flex;
        }

        .socialLink {
            margin: 0 16px;

            &:last-child {
                margin: 0;
            }

            Button {
                width: 100%;
                max-width: 70px;
            }
        }
    }

    @include from-breakpoint('x-large') {
        .header,
        .body {
            @include font(26, 28, 0.1, 400);

            span {
                @include font(30, 32, 0.1, 400);
            }
        }

        .header {
            h1 {
                @include font(30, 36, 0.1, 400);
            }
        }

        .body,
        .actions,
        .socialNetworkWrapper {
            margin: 0;
            max-width: 465px;

            p {
                margin: 16px 0;
            }
        }

        .actions {
            flex-flow: row;
            align-items: center;
            justify-content: space-between;

            Button {
                width: 168px;
            }
        }
    }
}

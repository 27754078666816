@import "../../../../styles/abstracts.scss";

.ListHeadquarters {
    @include featurePage;

    h1 {
        margin: 12px 0 0 0;
    }

    .container {
        display: flex;
        flex-flow: column;
        align-items: center;
        width: 100%;
        max-width: 450px;
        padding: 0 16px;
        overflow-y: auto;
        gap: 16px;
    }

    .card {
        background: colors("inputBg");
        cursor: pointer;
        padding: 16px;
        width: 100%;
        max-width: 350px;
        border-radius: 4px;
        text-align: center;

        .imageWrapper {
            width: 100%;
            height: 150px;
            margin: 8px 0;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .createButton {
        width: 90%;
        max-width: 350px;
        margin-top: 16px;
    }

}
@include from-breakpoint("x-large") {
    .ListHeadquarters {
        height: 78%;
        
        .container {
            max-width: 100%;
            margin: 16px 0;
            flex-flow: row;
            flex-wrap: wrap;
            justify-content: center;
        }
    
        .card {
            width: 290px;
        }
    }
}

@import '../../../../styles/abstracts.scss';

.ImageUpload {
    .label {
        display: flex;
        justify-content: center;
    }

    .input {
        display: none;
    }
}

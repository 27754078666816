@import '../../../../styles/abstracts.scss';

.Navigate {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 16px 0;

    @include from-breakpoint('x-large') {
        display: none;
    }
}

@import "../theme/index";

$iphone-five: 374px;
$iphone-se: 376px;
$x-small: 480px;
$small: 588px;
$medium: 768px;
$large: 1200px;

$breakpoints: (
    x-small: (
        null,
        $x-small - 1,
    ),
    small: (
        $x-small,
        $small - 1,
    ),
    medium: (
        $small,
        $medium - 1,
    ),
    large: (
        $medium,
        $large - 1,
    ),
    x-large: (
        $large,
        null,
    ),
);

$orientations: (
    portrait: portrait,
    landscape: landscape,
);

$colors: (
    "appBlueLight": $appBlueLight,
    "backgroundTransparent": $backgroundTransparent,
    "backgroundTransparentFilter": $backgroundTransparentFilter,
    "black": $black,
    "disabled": $disabled,
    "error": $error,
    "facebook": $facebook,
    "inputBg": $inputBg,
    "purple": $purple,
    "purpleLight": $purpleLight,
    "transparent": transparent,
    "white": $white,
);

$zindex: (
    "base": 1,
    "menu": 2,
    "overlay": 3,
    "modal": 4,
    "toast": 5,
    "modal-overlay": 10100,
);

@import '../../../../styles/abstracts.scss';
@import '../../Headquarters/HeadquartersCreate/HeadquartersCreate.module.scss';

.FieldsCreate {
    @extend .HeadquartersCreate;

    .formRow {
        gap: 8px;

        .inputLabel {
            min-width: 100px;
        }
    }

    .textField {
        margin: 0;
    }

    .imageIcon {
        margin-right: 4px;
    }

    @include from-breakpoint('x-large') {
        .formContent {
            max-width: 480px;
        }
    }
}
@import '../../../../styles/abstracts.scss';

.ReceiptsList {
    @include featurePage;

    .content {
        @include featureContainer;
        height: 100%
    }
    
    .receiptsContainer {
        @include infiniteScrollContainer(430px);
        position: relative;
    }

    .receiptsFilters {
        width: 100%; 
        margin-bottom: 12px;
    }

    .receiptsDates {
        display: flex;
        gap: 8px;
        margin-bottom: 8px;
    }
    
    .dateFilter {
        text-align: center;
    }

    .emptyData {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 250px;
    }

    .loading {
        @include infiniteLoader();
    }

    .loadingSmall {
        @include infiniteLoader('small');
    }

    .receipt {
        display: inline-block;
        width: 100%;
        background-color: $inputBg;
        padding: 12px 16px;
        border-radius: 5px;
        margin-bottom: 16px;

        &:last-of-type {
            margin-bottom: 0;
        }

        &Header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;

            .menuButton {
                color: $white;
            }
        }

        &Content {
        }
    }
}

.receiptImage {
    width: 100%;
    box-shadow: 1px 1px 12px 1px $black;
}

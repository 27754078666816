@import "../../../../styles/abstracts.scss";

.HeadquartersCreate {
    @include featurePage;

    .container {
        @include featureContainer;
        @include infiniteScrollContainer(380px);
    }

    .containerViewMode {
        @include infiniteScrollContainer(420px);
    }

    .textField {
        margin-bottom: 16px;
        width: 100%;
    }

    .checkbox {
        align-self: flex-end;
    }

    .label {
        min-width: 80px;
        text-align: right;
    }

    .imageIcon {
        margin-right: 4px;
    }

    .formRow {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        flex-wrap: wrap;

        &:first-child {
            justify-content: space-between;
        }
    }

    .formActions {
        margin: 16px;

        button {
            margin-bottom: 8px;
        }
    }

    .radioGroup {
        flex-direction: row;
        flex-wrap: nowrap;
        margin-right: 16px;
    }
    
    .imagesWrapper {
        max-width: calc(100vw - 105px);
        min-height: 164px;
        overflow-x: scroll;
        width: 100%;
        margin-bottom: 16px;

        .images {
            display: flex;
            flex-flow: row;
            gap: 8px;
        }

        .imageContainer {
            height: 150px;
            min-width: 250px;
            position: relative;
            border: 2px solid $white;
            border-radius: 5px;
        }

        .image {
            width: 100%;
            height: 100%;
        }

        .removeBtn {
            background: $purpleLight;
            border: 0;
            border-radius: 4px;
            padding: 0px 4px;
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    .errorMessage {
        text-align: center;
        color: $purpleLight;
    }

    @include from-breakpoint('x-large') {
        .container {
            max-width: 100%;
        }

        .formActions {
            display: flex;
            justify-content: center;
            gap: 16px;

            Button {
                max-width: 300px;
            }
        }
    }
}

@media screen and (min-width: $iphone-se) {
    .HeadquartersCreate {
        .formRow {
            flex-wrap: nowrap;
        }
    }
}

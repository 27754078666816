@import '../../../../styles/abstracts.scss';

.loader {
    @include loadingPage;
    width: 100%;
    padding: 16px;
    animation: zoom-in-zoom-out 2s ease-out infinite;
    max-height: 90%;

    .logoImage {
        max-width: 80px;
        width: 20%;
    }

    .logoImageSmall {
        width: 10%;
    }

    @keyframes zoom-in-zoom-out {
        0% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(1.5, 1.5);
        }
        100% {
            transform: scale(1, 1);
        }
    }
}

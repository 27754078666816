@import "../../../../styles/abstracts.scss";

.PagesHeader {
    color: colors("purpleLight");
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .element,
    .backIcon {
        flex: 1;
        align-items: center;
        justify-content: center;

        &:nth-child(2) {
            display: flex;
            justify-content: center;
            text-align: center;
        }
    }

    .element:nth-child(2) {
        flex-grow: 3;
    }

    @include from-breakpoint('medium') {
        .backIcon {
            display: flex;
            justify-content: flex-end;
        }
    }
}

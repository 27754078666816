@import "./../../../../styles/abstracts.scss";

.footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background: colors("black");
    height: 62px;
    padding: 8px 0 0 0;

    .footerWrapper {
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    .socialNetworks {
        display: flex;
    }

    .socialNetworkItem {
        display: flex;
        margin: 0 8px 0 0;

        .iconApp {
            margin: 0 6px 0 0;
        }

        a {
            text-decoration: none;
            color: colors("white");

            &:hover {
                text-decoration: underline;
            }
        }
    }

    @include from-breakpoint("medium") {
        .socialNetworkItem {
            margin: 0 24px 0 0;
        }
    }

    @include from-breakpoint("large") {
        background: colors("transparent");
        height: 62px;
    }
}

@import '../../../../styles/abstracts.scss';

.FieldsList {
    @include featurePage;

    .content {
        @include featureContainer;
        height: 100%;
    }
    
    .fieldsContainer {
        @include infiniteScrollContainer(360px);
        position: relative;
    }

    .field {
        width: 100%;
        height: fit-content;
        text-align: center;
        cursor: pointer;

        &:last-of-type {
            justify-self: flex-start;
        }
    }

    .fieldImage {
        img {
            width: 100%;
            height: 100%;
        }
    }
    
    .createButton {
        width: 90%;
        max-width: 350px;
        margin-top: 16px;
    }

    .emptyData {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 250px;
    }

    .loading {
        @include infiniteLoader();
    }

    .loadingSmall {
        @include infiniteLoader('small');
    }
}
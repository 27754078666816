@import '../../../../styles/abstracts.scss';

.friend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 16px;
    margin-bottom: 4px;
    border: 2px solid transparent;
    border-radius: 4px;

    &:last-child {
        margin-bottom: 0;
    }

    .favButton {
        background-color: transparent;
        border: 0;
    }

    .name {
        width: 100%;
        text-align: center;
    }

    &.active {
        border: 2px solid $black;
    }
}
@mixin at-breakpoint($bp, $orientation) {
    @if map-has-key($breakpoints, $bp) {
        $list: map-get($breakpoints, $bp);
        $bp: nth($list, 1);
    }

    @if map-has-key($orientations, $orientation) {
        $list: map-get($orientations, $orientation);
        $orientation: nth($list, 1);
    }

    @if ($orientation) {
        @media screen and (max-width: #{$bp}) and (orientation: #{$orientation}) {
            @content;
        }
    } @else {
        @media screen and (max-width: #{$bp}) {
            @content;
        }
    }
}

@mixin font($size: 14, $line: 18, $spacing: 0.1, $weight: 500) {
    $baseFont: 20;
    $fontSize: #{$size}px;
    $lineHeight: #{$line}px;
    $letterSpacing: #{$spacing}px;

    font-size: $fontSize;
    font-size: calculateRem($size, $baseFont) !important;
    line-height: $lineHeight;
    line-height: calculateRem($line, $baseFont) !important;
    font-weight: $weight !important;
    letter-spacing: $letterSpacing !important;
}

@mixin from-breakpoint($bp) {
    @if ($bp == "x-small") {
        @content;
    }
    @if ($bp == "ie-only") {
        @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            @content;
        }
    } @else {
        @if map-has-key($breakpoints, $bp) {
            $list: map-get($breakpoints, $bp);
            $bp: nth($list, 1);
        }

        @media screen and (min-width: #{$bp}) {
            @content;
        }
    }
}

@mixin gradientLine {
    content: "";
    position: absolute;
    top: -5px;
    bottom: -5px;
    background: linear-gradient(
        to bottom,
        colors("purple"),
        colors("appBlueLight")
    );
    width: 5px;
    border-radius: 5px;
}

@mixin borderGradient {
    background: colors("backgroundTransparent");
    position: relative;
    border: 5px solid transparent;
    border-top: 5px solid colors("purple");
    border-bottom: 5px solid colors("appBlueLight");
    border-radius: 5px;

    &::after {
        @include gradientLine;
        left: -5px;
    }

    &::before {
        @include gradientLine;
        right: -5px;
    }
}

@mixin authPage {
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
}

@mixin featurePage {
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    margin: 65px 0 0 0;
    height: 78%;

    @media screen and (min-width: $iphone-se) {
        height: 80%;
    }

    @include from-breakpoint('x-large') {
        margin: 0;
    }
}

@mixin featureContainer {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    max-width: 450px;
    padding: 0 16px;
}

@mixin featureMain {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include from-breakpoint('x-large') {
        justify-content: flex-start;
    }
}

@mixin loadingPage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

@mixin formLayout {
    width: 100%;
    padding: 0 16px;
    margin: 8px 0;
    overflow: auto;
    max-width: 450px;

    Button {
        width: 100%;
        margin: 8px 0;
    }
}

@mixin fieldGroup {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin: 16px 0;
}

@mixin fieldGroupItem {
    margin-right: 4px;
    width: 50%;

    &:last-child {
        margin-right: 0px;
        margin-left: 4px;
    }
}

@mixin singleField {
    width: 100%;
    margin: 16px 0;
}

@mixin infiniteScrollContainer($height) {
    width: 100%;
    overflow: auto;
    height: calc(100vh - #{$height - 30px});

    @media screen and (min-width: $iphone-se) {
        height: calc(100vh - #{$height});
    }
}
@mixin infiniteLoader($type: 'medium') {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @if ($type == 'medium') {
        position: absolute;
    }
}

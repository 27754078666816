@import "../../../../styles/abstracts.scss";

.payment {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $grey;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px 0 20px;

    .title {
        @include font(18, 22);
        color: $white;
    }

    .qrImage {
        margin: 16px 0 16px 0;
        max-width: 120px;
    }
    
    .bankInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;

        span {
            line-height: 16px;
            margin-bottom: 5px;
        }
    }

    .box {
        display: flex;
        justify-content: space-evenly;
        border-radius: 5px;
        background-color: $inputBg;
        padding: 6px 8px;
        min-width: 100px;
        margin-bottom: 8px;
    }

    .button {
        margin: 32px 0;
        width: 100%;
        max-width: 250px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        
        a {
            color: $white;
        }

        p {
            @include font(18, 22);
            margin-bottom: 16px;
        }
    }
}

.receiptImage {
    width: 100%;
    box-shadow: 1px 1px 12px 1px $black;
}

@include from-breakpoint('small') {
    .payment {
        padding: 60px 20px 0 20px;

        .qrImage {
            max-width: 200px;
        }
    }
}